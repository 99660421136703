import React from 'react';
import { useNavigate } from 'react-router-dom';

const ArticleBlogCarte = ({ article }) =>  {
  const { id, imageUrl, filtre, readingTime, titre, listeSections = [], descriptif } = article;
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/article/${id}`);
  };
  
  // ...Lire la suite truncate fonction
  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + '... Lire la suite' : text;
  };


  return (
    <div className="article-blog-carte" onClick={handleCardClick}>
      <img className="image" src={imageUrl || '/default-image.png'} alt={titre || 'Article'} />
      <div className="info">
        <div className="headerBlog">
          <div className="category">
            <i className="fas fa-tag"></i> {filtre || 'Sans filtre'}
          </div>
          <div className="reading-time">
            <img src="/assets/images/alarm.png" alt="Réveil" />
            <span className="time">{readingTime || '0 min'}</span>
          </div>
        </div>
        <h2 className="title">{titre || 'Titre indisponible'}</h2>
        <div className="keywords">
          {(listeSections.length > 0 ? listeSections : ['Aucune section active']).map((section, index) => (
            <span key={index} className="keyword">{section}</span>
          ))}
        </div>
        <p className="excerpt">
          {truncateText(descriptif || 'Aucun descriptif disponible', 150)}
        </p>
      </div>
    </div>
  );
}

export default ArticleBlogCarte;