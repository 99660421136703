import React from 'react';
import { useNavigate } from 'react-router-dom';
import AsideSimulateur from '../../components/simulateur/AsideSimulateur';

const SimulateurSecuriteQuatre = () => {
    const navigate = useNavigate();

    const handleRectangleClick = () => {
        navigate('/simulateur/securite-cinq');
    };

    return (
        <div className="simulateur">
            <AsideSimulateur page={4} />
            <div className="simulateur-content">
                <main className="main main-sec-quatre"> {/* Ajoutez une classe ici */}
                    <h1>Sélectionnez le niveau de résistance souhaité</h1>
                    <div className="rectangle-grid smaller-rectangles">
                        <div className="rectangle" onClick={handleRectangleClick}>Forte</div>
                        <div className="rectangle" onClick={handleRectangleClick}>Moyenne</div>
                        <div className="rectangle" onClick={handleRectangleClick}>Faible</div>
                        <div className="rectangle" onClick={handleRectangleClick}>N/A</div>
                    </div>
                    <div className="info-box">
                        <p>
                            Il existe plusieurs épaisseurs de film, qui protège d’une intensité de risque différent. 
                            Le 120 microns (faible résistance) vous protégera d’un risque d’intrusion ou de vandalisme, 
                            le 210 microns (moyenne résistance) sera recommandé pour les forts risques d’intrusions, 
                            mais surtout sur les petites explosions, le 340 microns (forte résistance) absorbera 
                            l’énergie créée par l’onde de choc.
                        </p>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default SimulateurSecuriteQuatre;
