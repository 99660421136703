import React from 'react';
import Erreur from '../commun/Erreur';

//Description section between Domaine section and Jps Films section of /template-section

const SectionDescriptifs = ({ data }) => {

  if (!data) {return <div><Erreur /></div>;}

  return (
    <div className="section-descriptifs">
        <p className='first-p'>{data.Introduction || 'Introduction non disponible'}</p>
        <h3 className='first-h3'><strong>{data.TitreUn}</strong></h3>
        <p>{data.DescriptifUn}</p>
        <h3><strong>{data.TitreDeux}</strong></h3>
        <p>{data.DescriptifDeux}</p>
        <h3><strong>{data.TitreTrois}</strong></h3>
        <p>{data.DescriptifTrois}</p>
        <h3><strong>{data.TitreQuatre}</strong></h3>
        <p>{data.DescriptifQuatre}</p>
        <h3><strong>{data.TitreCinq}</strong></h3>
        <p>{data.DescriptifCinq}</p>
    </div>
  );
};

export default SectionDescriptifs;
