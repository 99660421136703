import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AsideSimulateur = ({ page, showBackButton = true }) => {
    const navigate = useNavigate();

    //return button
    const handleBackClick = () => {
        navigate(-1);
    };

    // line page count
    const totalPages = 4;
    const traits = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <aside className="aside">
            <div className="aside-header">
                <Link to="/">
                    <img src="/Logo blanc horizontal.png" alt="LogoJPS" className="logo logo-desktop" />
                    <img src="/Logo bleu horizontal.png" alt="LogoJPS" className="logo logo-mobile" />
                </Link>
                    <button className="back-button" onClick={handleBackClick}>
                        <span className="arrow">↑</span>
                        <span className="return">RETOUR</span>
                    </button>
            </div>
            <div className="aside-content">
                <h2>Parlons un peu de votre projet</h2>
                <p>Que nous vaut votre visite ?</p>
                <div className="traits">
                    {traits.map((_, index) => (
                        <div
                            key={index}
                            className="trait"
                            style={{
                                opacity: index < page ? '1' : '0.2',
                            }}
                        ></div>
                    ))}
                </div>
            </div>
            <div className="aside-footer">
                <Link to="/" className="home-button">
                    Retour Accueil
                </Link>
            </div>
        </aside>
    );
};

export default AsideSimulateur;
