import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion'; 
import Erreur from '../commun/Erreur';

//ForwardRef to get MediaBandeau ref for ArticleBlogPage to have JPSMetaview scroll fixed
//Componant also used for /plan-de-site, /mentions-legales, /politique-de-conf pages
const ArticleBlogDescriptif = forwardRef(({ data }, ref) => {
  const { Filtre, Titre, BlocTexteUn, BlocTexteDeux, BlocTexteTrois, MediaUn, MediaDeux, MediaBandeau } = data;

  // render strapi optionnal text
  const renderContent = (blocks) => {
    if (!Array.isArray(blocks)) {
      console.error('Les blocs ne sont pas un tableau:', blocks);
      return <p className="paragraph">Aucun contenu à afficher.</p>;
    }

    return blocks.map((block, index) => {
      if (!block || !block.children) {
        return <p key={index} className="paragraph">Bloc sans contenu.</p>;
      }

      switch (block.type) {
        case 'heading':
          switch (block.level) {
            case 1:
              return (
                <h1 key={index} className="title-h1">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h1>
              );
            case 2:
              return (
                <h2 key={index} className="title-h2">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h2>
              );
            case 3:
              return (
                <h3 key={index} className="title-h3">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h3>
              );
            case 4:
              return (
                <h4 key={index} className="title-h4">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h4>
              );
            case 5:
              return (
                <h5 key={index} className="title-h5">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h5>
              );
            default:
              return (
                <h6 key={index} className="title-default">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h6>
              );
          }
        case 'paragraph':
          return (
            <p key={index} className="paragraph">
              {block.children.map((child, childIndex) => {
                if (child.type === 'link') {
                  return (
                    <a key={childIndex} href={child.url} className="link">
                      {child.children[0]?.text}
                    </a>
                  );
                }
                return <span key={childIndex}>{child.text}</span>;
              })}
            </p>
          );
        case 'list':
          return (
            <ul key={index} className="list">
              {block.children.map((listItem, itemIndex) => (
                <li key={itemIndex} className="list-item">
                  <p className='bullet'>•</p> {/* Puce typographique */}
                  {listItem.children.map((child, childIndex) => {
                    if (child.type === 'link') {
                      return (
                        <a key={childIndex} href={child.url} className="link">
                          {child.children[0]?.text}
                        </a>
                      );
                    }
                    return <span key={childIndex}>{child.text}</span>;
                  })}
                </li>
              ))}
            </ul>
          );
        default:
          console.warn('Type de bloc inconnu:', block.type);
          return <p className="paragraph">Bloc inconnu.</p>;
      }
    });
  };

  // fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5 } }
  };

  if (!data) {return <div><Erreur /></div>;}

  return (
    <div className="article-blog-descriptif">
      {MediaBandeau && MediaBandeau.data && MediaBandeau.data.attributes && (
        <div ref={ref} className="bandeau-media">
          <img
            className="img-bandeau"
            src={MediaBandeau.data.attributes.url}
            alt={MediaBandeau.data.attributes.name || 'Image du bandeau'}
          />
          <motion.div
            className="bandeau-content"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <h3>{Filtre}</h3>
            <h1>{Titre}</h1>
          </motion.div>
        </div>
      )}

      {/* Container for main and aside */}
      <div className="content-container">
        <main>
          {BlocTexteUn && BlocTexteUn.length > 0 && (
            <div className="bloc-texte bloc-texte-un">
              {renderContent(BlocTexteUn)}
            </div>
          )}
          {MediaUn && MediaUn.data && Array.isArray(MediaUn.data) && MediaUn.data.length > 0 && (
            MediaUn.data.map((media) => (
              <img className="img-media" key={media.id} src={media.attributes.url} alt={media.attributes.name || 'Image'} />
            ))
          )}
          {BlocTexteDeux && BlocTexteDeux.length > 0 && (
            <div className="bloc-texte bloc-texte-deux">
              {renderContent(BlocTexteDeux)}
            </div>
          )}
          {MediaDeux && MediaDeux.data && Array.isArray(MediaDeux.data) && MediaDeux.data.length > 0 && (
            MediaDeux.data.map((media) => (
              <img className="img-media" key={media.id} src={media.attributes.url} alt={media.attributes.name || 'Image'} />
            ))
          )}
          {BlocTexteTrois && BlocTexteTrois.length > 0 && (
            <div className="bloc-texte bloc-texte-trois">
              {renderContent(BlocTexteTrois)}
            </div>
          )}
        </main>
      </div>
    </div>
  );
});

ArticleBlogDescriptif.propTypes = {
  data: PropTypes.shape({
    Titre: PropTypes.string.isRequired,
    BlocTexteUn: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ),
    BlocTexteDeux: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ),
    BlocTexteTrois: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ),
    MediaUn: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          attributes: PropTypes.shape({
            url: PropTypes.string.isRequired,
            name: PropTypes.string,
          }).isRequired,
        })
      ),
    }),
    MediaDeux: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          attributes: PropTypes.shape({
            url: PropTypes.string.isRequired,
            name: PropTypes.string,
          }).isRequired,
        })
      ),
    }),
    MediaBandeau: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        attributes: PropTypes.shape({
          url: PropTypes.string.isRequired,
          name: PropTypes.string,
        }).isRequired,
      }),
    }),
  }),
  jpsMetaview: PropTypes.bool,
};

ArticleBlogDescriptif.defaultProps = {
  data: {
    BlocTexteUn: [],
    BlocTexteDeux: [],
    BlocTexteTrois: [],
    MediaUn: { data: [] },
    MediaDeux: { data: [] },
    MediaBandeau: { data: {} },
  },
  jpsMetaview: false,
};

export default ArticleBlogDescriptif;
