import React from 'react';
import { useNavigate } from 'react-router-dom';

//Little fixed buttons in the right of the screen

const BoutonsDevisRdvWhatsapp = () => {
  const navigate = useNavigate();

  const handleDevisClick = () => {
    navigate('/contact');
  };

  const handleWhatsappClick = () => {
    window.open('https://wa.me/33660395848', '_blank');
  };

  return (
    <div className="floating-buttons">
      <button aria-label="bouton devis rdv" className="btn-floating btn-devis" onClick={handleDevisClick}>
        <p>DEVIS<br />RDV</p>
      </button>
      <button  aria-label="bouton whatsapp" className="btn-floating btn-whatsapp" onClick={handleWhatsappClick}>
        <i className="fab fa-whatsapp"></i>
      </button>
    </div>
  );
};

export default BoutonsDevisRdvWhatsapp;
