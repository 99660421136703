import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Erreur from './Erreur';

//Nav for Mobile only

const NavBarMobile = ({ onClose }) => {
  const [sections, setSections] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [submenuData, setSubMenuData] = useState([]);
  const [loadingSubmenu, setLoadingSubmenu] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des données.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  // Fetch data sections
  useEffect(() => {
    const fetchSections = async () => {
      setLoading(true);
      try {
        const response = await fetchData(`${process.env.REACT_APP_API_URL}/api/sections?populate=*`);
        if (response) {
          setSections(response);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des sections:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSections();
  }, []);

  // Fetch data articles by sections
  useEffect(() => {
    const fetchSubmenuData = async () => {
      if (activeSection !== null) {
        setLoadingSubmenu(true);
        const submenuUrl = `${process.env.REACT_APP_API_URL}/api/articles?filters[sectionId][$eq]=${activeSection}&populate=*`;
        try {
          const response = await fetchData(submenuUrl);
          if (response) {
            setSubMenuData(response);
          } else {
            setSubMenuData([]);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des sous-menus:', error);
        } finally {
          setLoadingSubmenu(false);
        }
      } else {
        setSubMenuData([]);
      }
    };
    fetchSubmenuData();
  }, [activeSection]);

  //Chevron logiq
  const handleSectionClick = (id) => {
    if (activeSection === id) {
      setActiveSection(null);
    } else {
      setActiveSection(id);
    }
    onClose();
  };

  const handleChevronClick = (e, id) => {
    e.stopPropagation();
    if (activeSection === id) {
      setActiveSection(null);
    } else {
      setActiveSection(id);
    }
  };

  // Reinit section and submenu wjen NavBarMobile is close
  useEffect(() => {
    if (!onClose) {
      setActiveSection(null);
      setSubMenuData([]);
    }
  }, [onClose]);

  if (loading) return <div></div>;
  if (error) return <Erreur />;

  return (
    <div className='mobile-menu'>
      <nav className='mobile-nav'>
        <div className='mobile-title'>NOS FILMS</div>
        {sections.sort((a, b) => a.id - b.id).map((section) => (
          <div key={section.id} className={`mobile-section ${activeSection === section.id ? 'active' : ''}`}>
            <div className='mobile-section-header'>
              <Link to={`/section/${section.id}`} onClick={() => handleSectionClick(section.id)}>
                <h4>{section.attributes.title}</h4>
              </Link>
              <span className="chevron" onClick={(e) => handleChevronClick(e, section.id)}>
                <i className={`fas fa-chevron-${activeSection === section.id ? 'up' : 'down'}`}></i>
              </span>
            </div>
            {activeSection === section.id && (
              <motion.div className='submenu'>
                {loadingSubmenu ? (
                  <div></div>
                ) : (
                  submenuData.sort((a, b) => a.id - b.id).map((item) => (
                    <Link key={item.id} to={`/template-sous-section/${item.id}`} onClick={onClose}>
                      {item.attributes.title}
                    </Link>
                  ))
                )}
              </motion.div>
            )}
          </div>
        ))}
        <Link to="/guides-reglementations" className='mobile-link' onClick={onClose}>GUIDE ET RÉGLEMENTATIONS</Link>
        <Link to="/nos-services" className='mobile-link' onClick={onClose}>NOS SERVICES</Link>
        <Link to="/nos-realisations" className='mobile-link' onClick={onClose}>NOS RÉALISATIONS</Link>
        <Link to="/nos-articles" className='mobile-link' onClick={onClose}>ACTUALITÉS</Link>
        <Link to="/contact" className='mobile-link' onClick={onClose}>NOUS CONTACTER</Link>
      </nav>
    </div>
  );
}

export default NavBarMobile;