import React from 'react';

//two differents domaines (different scss) banner in this componant : 
//one in /accueil (domaines) 
//and another one in /template - section (template-section)

const Domaines = ({domainesData = [],
  hideTitle = false,
  hideBorder = false,
  hideIcons = false,
  imageSize = { width: '90%', height: 'auto' },
  isTemplateSection = false
}) => {

  return (
    <div className={`domaines ${isTemplateSection ? 'template-section' : ''}`}>
      {!hideTitle && <h2 className={`domaines__title ${isTemplateSection ? 'template-section__title' : ''}`}>Nos domaines d’intervention</h2>}
      <div className={`domaines__grid ${isTemplateSection ? 'template-section__grid' : ''}`}>
        {domainesData.map(domaine => (
          <div key={domaine.id} className={`domaines__item ${hideBorder ? 'hide-border' : ''} ${isTemplateSection ? 'template-section__item' : ''}`}>
            <div className={`domaines__image ${isTemplateSection ? 'template-section__image' : ''}`}>
              <img 
                src={domaine.attributes.Image.data.attributes.url} 
                alt={domaine.attributes.Titre} 
              />
            </div>
            <div className="domaines__content">
              <h3 className={`domaines__item-title ${isTemplateSection ? 'template-section__item-title' : ''}`}>
                {domaine.attributes.Titre}
              </h3>

              {Array.isArray(domaine.attributes.Descriptif) ? (
                <ul className={`domaines__list ${isTemplateSection ? 'template-section__list' : ''}`}>
                  {domaine.attributes.Descriptif.map((item, index) => (
                    <li key={index} className={isTemplateSection ? 'template-section__list-item' : ''}>
                      {!hideIcons && <span className={`domaines__list-icon ${isTemplateSection ? 'template-section__list-icon' : ''}`}>&gt;</span>} 
                      {item}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className={`domaines__list ${isTemplateSection ? 'template-section__list' : ''}`}>
                  {domaine.attributes.Descriptif.split('>').map((item, index) => (
                    item && (
                      <li key={index} className={isTemplateSection ? 'template-section__list-item' : ''}>
                        {!hideIcons && <span className={`domaines__list-icon ${isTemplateSection ? 'template-section__list-icon' : ''}`}>&gt;</span>}
                        {item.trim()}
                      </li>
                    )
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default Domaines;
