import React from 'react';
import { Link } from 'react-router-dom';

//Bloc present in CarrouselBlog and pages /nos-articles ,  /article

const JPSMetaview = () => {
  return (
    <div className="jps-metaview">
      <img 
        className="jps-metaview__image" 
        src="/assets/images/Complexe sportif en tete 2.png" 
        alt="Complexe sportif" 
      />
      <div className="jps-metaview__content">
        <h1 className="jps-metaview__title">
          JPS Metaview
          <br />
          Trouvez le produit qu’il vous faut grâce à notre simulateur
          <br />
          de projet
        </h1>
        <h2 className="jps-metaview__subtitle">
          Votre simulation gratuitement
        </h2>
        <Link to="/simulateur" className="jps-metaview__button">
          COMMENCER
        </Link>
      </div>
    </div>
  );
}

export default JPSMetaview; 