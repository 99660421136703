import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Erreur from '../commun/Erreur';

//important componant used in almost every pages 
//Used as first Banner in screen with just Media and title  in /template-sous-section  ,  /guides-reglementations  ,  /faq
//Used as Banner with just Media (Vid and pictures) in /accueil  ,  /template-section  ,  /nos-services  ,  /qui-sommes-nous

export default function BandeauMedia({ data, hideTitle, align = 'center' }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const location = useLocation();

  const mediaUrl = data.Media.data.attributes.url || data.Media.url;
  const mediaType = data.Media.data.attributes.ext || data.Media.ext;
  const title = data.Titre;

  //Thumbnail videos 
  const posterUrl = data.Vignette && data.Vignette.data && data.Vignette.data.length > 0
    ? data.Vignette.data[0].attributes.url
    : '';

  //Play button
  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  // Stop vid when load another page
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
      setIsPlaying(false);
    }
  }, [location]);

  const contentClass = `bandeau-media__content bandeau-media__content--${align}`;

  if (!data || !data.Media || !data.Media.data || !data.Media.data.attributes) {
    return <div><Erreur /></div>;
  }

  return (
    <div className="bandeau-media">
      {!hideTitle && title && <h2 className="bandeau-media__title">{title}</h2>}
      <div className={contentClass}>
        {mediaType === '.mp4' ? (
          <>
            <div className="bandeau-media__video-container">
              <video
                key={mediaUrl}
                className={`bandeau-media__media ${!isPlaying ? 'bandeau-media__media--hidden-controls' : ''}`}
                ref={videoRef}
                onPlay={() => setIsPlaying(true)}
                onPause={handlePause}
                poster={posterUrl}
                controls={isPlaying}
              >
                <source src={mediaUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {!isPlaying && (
                <div className="bandeau-media__play-button" onClick={handlePlayClick}>
                  <i className="fa fa-play"></i>
                </div>
              )}
            </div>
          </>
        ) : (
          <img className="bandeau-media__media" src={mediaUrl} alt={title || 'Media'} />
        )}
      </div>
    </div>
  );
}

BandeauMedia.propTypes = {
  data: PropTypes.object.isRequired,
  hideTitle: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

