import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Header from '../components/commun/Header';
import Footer from '../components/commun/Footer';
import Form from '../components/commun/Form';
import BoutonsDevisRdvWhatsapp from '../components/commun/BoutonsDevisRdvWhatsapp';
import { Helmet } from 'react-helmet';

export default function Contact({ customStyles }) {

  //fade in animations
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const slideInVariants = {
    hidden: { opacity: 0, y: 50, },
    visible: {
      opacity: 1, y: 0,
      transition: { duration: 0.8, ease: 'easeOut', },
    },
  };

  // data for SEO
  const data = {
    Titre: 'Contactez JPS Films',
    Filtre: 'Nous sommes là pour répondre à vos questions et vous accompagner dans vos projets.',
    Image: '/assets/images/Meeting.png',
  };
  
  return (
    <div className="contact-page-wrapper">
      <Helmet>
        <title>{data?.Titre || 'Contacter JPS Films'}</title>
        <meta name="description" content={data?.Filtre || 'Nous sommes là pour répondre à vos questions et vous accompagner dans vos projets.'} />
        <meta property="og:title" content={data?.Titre || 'Contacter JPS Films'} />
        <meta property="og:description" content={data?.Filtre || 'Nous sommes là pour répondre à vos questions et vous accompagner dans vos projets.'} />
        <meta property="og:image" content={data?.Image || `${window.location.origin}/logo.png`} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="contact-page">
        <motion.div
          className="text-section"
          ref={ref}
          variants={slideInVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        >
          <h1>Vous avez un projet ? Besoin d’être conseillé ?</h1>
          <p>
            Besoin d'un devis ou de conseil sur nos films adhésifs. Vous souhaitez protéger une vitrine, diminuer la chaleur d’un bureau, créer un espace de confidentialité, … Nous sommes là pour vous accompagner.
          </p>
          <p>
            Un Expert JPS Films vous contactera au plus vite pour vous aider à trouver le produit idéal en fonction de vos besoins et vos contraintes.
          </p>
          <p className="bold-text">
            Nous sommes là pour vous accompagner et vous conseiller !
          </p>
          <p>
            Nous sommes ouvert du :<br />
            Lundi au Vendredi de 9h à 18h
          </p>
          <p>
            <a href="tel:0437546633">04 37 54 66 33</a><br />
            <a href="mailto:maeva@jpsfilms.fr">maeva@jpsfilms.fr</a>
          </p>
        </motion.div>
        <div className="image-section">
          <div className="form-container">
            <Form />
          </div>
        </div>
        <BoutonsDevisRdvWhatsapp customStyles={customStyles?.floatingButtons} />
      </div>
      <Footer />
    </div>
  );
}
