import React from 'react';
import { useNavigate } from 'react-router-dom';
import RectangleGrid from '../../components/simulateur/RectangleGrid';
import AsideSimulateur from '../../components/simulateur/AsideSimulateur';

const SimulateurDeux = () => {
    const navigate = useNavigate();

    const items = [
        'Immeuble de bureau', 'Commerce', 'Complexe sportif', 'Établissement scolaire',
        'Établissement médical', 'Résidence', 'Collectivité', 'Monument & patrimoine', 'Autre'
    ];

    const handleRectangleClick = (item) => {
        navigate('/simulateur/trois');
    };

    return (
        <div className="simulateur">
            <AsideSimulateur page={2} />
            <div className="simulateur-content">
                <main className="main">
                    <h1>De quel type de bâtiment s’agit-il ?</h1>
                    <RectangleGrid items={items} onClick={handleRectangleClick} />
                </main>
            </div>
        </div>
    );
};

export default SimulateurDeux;
