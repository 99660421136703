import React from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { motion, useInView } from 'framer-motion';

//Banner used in /nos-services and /qui-sommes-nous pages

const BandeauDescriptif = ({ data, customStyles, reverse, primaryButtonLink, className }) => {
  const { Titre, Descriptif, Liste, BoutonUn, Media } = data;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true,threshold: 0.3 });
  const navigate = useNavigate();

  const imageUrl = Media?.data?.[0]?.attributes?.url || '';

  //fade in animation
  const slideFromBottom = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    // <div className={`bandeau-descriptif ${reverse ? 'reverse' : ''}`}>
        <div className={`bandeau-descriptif ${className} ${reverse ? 'reverse' : ''}`}>
      <div className="image-section">
        <img src={imageUrl} alt={Titre} />
      </div>
        <motion.div
          className="text-section"
          initial="hidden"
          animate={isInView ? 'visible' : 'hidden'} 
          transition={{ duration: 0.8 }}
          variants={slideFromBottom}
          ref={ref}
        >
        {Titre && <h2 className="titre">{Titre}</h2>}
        {Descriptif && Descriptif.map((paragraph, index) => (
          <p key={index}>{paragraph.children[0]?.text || ''}</p>
        ))}
        {Array.isArray(Liste) && Liste.length > 0 && (
          <ul className="liste">
            {Liste.map((item, index) => (
              <li key={index} style={customStyles?.listItem}>
                <i className="fas fa-check"></i> {item.children[0]?.text || ''}
              </li>
            ))}
          </ul>
        )}
        {BoutonUn && (
          <button
            className="btn-primary"
            onClick={() => navigate(primaryButtonLink)}
          >
            {BoutonUn.toUpperCase()}
          </button>
        )}
      </motion.div>

    </div>
  );
};

BandeauDescriptif.propTypes = {
  data: PropTypes.shape({
    Titre: PropTypes.string,
    Descriptif: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ),
    Liste: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ),
    BoutonUn: PropTypes.string,
    Media: PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  customStyles: PropTypes.shape({
    container: PropTypes.object,
    imageSection: PropTypes.object,
    image: PropTypes.object,
    textSection: PropTypes.object,
    title: PropTypes.object,
    text: PropTypes.object,
    list: PropTypes.object,
    listItem: PropTypes.object,
    button: PropTypes.object,
  }),
  reverse: PropTypes.bool,
  buttonLink: PropTypes.string,
};

export default BandeauDescriptif;
