import PropTypes from 'prop-types';
import { useState } from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useInView } from 'framer-motion';
import CompareImage from 'react-compare-image';

const BandeauMediaAnimation = ({ data }) => {
  const [leftImageIndex, setLeftImageIndex] = useState(1);
  const [rightImageIndex, setRightImageIndex] = useState(1);
  const {Titre, Descriptif, Liste, DescriptifDeux, Boutton} = data.attributes;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.3 });
  const textClass = data.id === 1 ? '' : 'hidden';
  const buttonLink = data.id === 1 ? '/template-sous-section/3' :
  data.id === 2 ? '/section/2' :
  data.id === 3 ? '/section/3' : '#';


  //Compare id 2 onClick
  const leftImageCount = 3;
  const rightImageCount = 9;
  
  const handleLeftClick = () => {
    if (leftImageIndex < leftImageCount) {
      setLeftImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleRightClick = () => {
    if (rightImageIndex < rightImageCount) {
      setRightImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  //fade in animation
  const slideFromBottom = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  
  return (
    <div className={`bandeau-media-animation ${data.id === 2 ? 'row-reverse' : ''} id-${data.id}`}>
      
      {/* Compare image section */}
      <div className="bandeau-media-animation__images">
        {data.id === 1 && (
          <h1>Découvrir nos films adhésifs pour vitre</h1>
        )}
        {data.id === 1 && (
          <div className="bandeau-media-animation__iframe-container">
            <CompareImage
              leftImage={`${process.env.PUBLIC_URL}/assets/images/Comparatif-sobriete-avec-film.png`}
              rightImage={`${process.env.PUBLIC_URL}/assets/images/Comparatif-sobriete-sans-film.png`}
            />
            <div className="bandeau-media-animation__instruction">
              Déplacez le curseur
            </div>
          </div>
        )}
        {data.id === 2 && (
          <div className="bandeau-media-animation__image-container">
            <div className='bandeau-media-animation__image' style={{ display: 'flex'}}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Property-Left-${leftImageIndex}.png`}
                alt={`Animation Left ${leftImageIndex}`}
                style={{ width: '50%'}}
                onClick={handleLeftClick}
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Property-Right-${rightImageIndex}.png`}
                alt={`Animation Right ${rightImageIndex}`}
                style={{ width: '50%'}}
                onClick={handleRightClick}
              />
            </div>
            <div className="bandeau-media-animation__instruction">
              Tapez sur le verre de gauche et droite pour casser le verre
            </div>
          </div>
        )}
        {data.id === 3 && (
          <div className="bandeau-media-animation__iframe-container">
            <CompareImage
              leftImage={`${process.env.PUBLIC_URL}/assets/images/Comparatif occultant vue interieure.png`}
              rightImage={`${process.env.PUBLIC_URL}/assets/images/Comparatif occultant vue exterieure.png`}
            />
            <div className="bandeau-media-animation__instruction">
              Déplacez le curseur
            </div>
          </div>
        )}
      </div>

      {/* Compare Text Section */}
      <div className={`bandeau-media-animation__text ${textClass}`}>
      <motion.div
          className="text-section"
          initial="hidden"
          animate={isInView ? 'visible' : 'hidden'}
          transition={{ duration: 0.8 }}
          variants={slideFromBottom}
          ref={ref}
        >
        <h2>{Titre}</h2>
        <p>{Descriptif}</p>
        {Liste && (
          <ul>
            {Liste.map((item, index) => (
              <li key={index}>
                <i className="fas fa-check"></i> {item.children[0].text}
              </li>
            ))}
          </ul>
        )}
        {DescriptifDeux && <p>{DescriptifDeux}</p>}
        <Link to={buttonLink}>
          <button>{Boutton}</button>
        </Link>
        </motion.div>
      </div>
    </div>
  );
};

BandeauMediaAnimation.propTypes = {
  data: PropTypes.object.isRequired
};

export default BandeauMediaAnimation;
