import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Erreur from '../commun/Erreur';

const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const LogosPartenaires = () => {
  const [logos, setLogos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des logos.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const logosResponse = await fetchData(`${API_BASE_URL}/accueil-logos?populate=*`);
        if (logosResponse && logosResponse.length > 0) {
          setLogos(logosResponse[0].attributes.Logos.data || []);
        } else {
          setLogos([]); 
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des logos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (loading) return <div></div>;
  if (error) return <div><Erreur /></div>;
  if (logos.length === 0) {return <div>Aucun logo disponible pour le moment.</div>;}

  return (
    <div className="logos-partenaires-wrapper">
      <div className="logos-partenaires">
        {logos.map((logo) => (
          <img
            key={logo.id}
            src={logo.attributes.url}
            alt={logo.attributes.alternativeText || logo.attributes.name}
            className="logo"
          />
        ))}
        {/* Duplicate for infinite logos */}
        {logos.map((logo) => (
          <img
            key={`duplicate-${logo.id}`}
            src={logo.attributes.url}
            alt={logo.attributes.alternativeText || logo.attributes.name}
            className="logo"
          />
        ))}
      </div>
    </div>
  );
}

export default LogosPartenaires; 