import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

//Blue Form on /contact page with emailjs 

const Form = () => {
  const [selectedNeeds, setSelectedNeeds] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //checkbox button selected fonction
  const handleNeedChange = (event) => {
    const value = event.target.value;
    setSelectedNeeds(prevNeeds =>
      prevNeeds.includes(value)
        ? prevNeeds.filter(need => need !== value)
        : [...prevNeeds, value]
    );
  };

  const closePopup = () => {
    setIsSubmitted(false);
    document.body.style.overflow = 'auto';
  };

  //email js 
  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;

    emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YYOUR_TEMPLATE_ID, form, process.env.REACT_APP_YYOUR_PUBLIC_KEY) // Remplace par ton 'service_id', 'template_id' et 'user_id' d'EmailJS
      .then((result) => {
          console.log(result.text);
          setIsSubmitted(true);
      }, (error) => {
          console.log(error.text);
      });

    form.reset();
  };

  return (
    <div className="contact-form-container">
      <h2>Demander un devis gratuit</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Besoin(s)</label>
          <div className="form-needs">
            <div className="column">
              <label className="radio-checkbox">
                <input 
                  type="checkbox" 
                  name="needs" 
                  value="chaleur" 
                  checked={selectedNeeds.includes('chaleur')}
                  onChange={handleNeedChange} 
                /> 
                <span className="custom-radio"></span>
                Chaleur & éblouissement
              </label>
              <label className="radio-checkbox">
                <input 
                  type="checkbox" 
                  name="needs" 
                  value="protection" 
                  checked={selectedNeeds.includes('protection')}
                  onChange={handleNeedChange} 
                /> 
                <span className="custom-radio"></span>
                Protection & Sécurité
              </label>
              <label className="radio-checkbox">
                <input 
                  type="checkbox" 
                  name="needs" 
                  value="confidentialité" 
                  checked={selectedNeeds.includes('confidentialité')}
                  onChange={handleNeedChange} 
                /> 
                <span className="custom-radio"></span>
                Vis à vis & confidentialité
              </label>
            </div>
            <div className="column">
              <label className="radio-checkbox">
                <input 
                  type="checkbox" 
                  name="needs" 
                  value="decoration" 
                  checked={selectedNeeds.includes('decoration')}
                  onChange={handleNeedChange} 
                /> 
                <span className="custom-radio"></span>
                Décoration
              </label>
              <label className="radio-checkbox">
                <input 
                  type="checkbox" 
                  name="needs" 
                  value="films_speciaux" 
                  checked={selectedNeeds.includes('films_speciaux')}
                  onChange={handleNeedChange} 
                /> 
                <span className="custom-radio"></span>
                Films spéciaux
              </label>
              <label className="radio-checkbox">
                <input 
                  type="checkbox" 
                  name="needs" 
                  value="autres" 
                  checked={selectedNeeds.includes('autres')}
                  onChange={handleNeedChange} 
                /> 
                <span className="custom-radio"></span>
                Autres
              </label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Nom de l'entreprise*</label>
          <input type="text" name="company_name" placeholder="JPS Films" required />
        </div>
        <div className="form-group">
          <label>Nom et prénom</label>
          <input type="text" name="name" placeholder="Doe John" />
        </div>
        <div className="form-group">
          <label>Email*</label>
          <input type="email" name="email" placeholder="john@Doe.com" required />
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea name="message" placeholder="Écrire ici"></textarea>
        </div>
        <p className="form-disclaimer">
          En cliquant sur Envoyer vous nous autorisez à collecter vos données. Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par la société JPS Films France pour vous renseigner selon votre demande. Les données collectées seront communiquées aux seuls destinataires suivants : responsable commercial. Les données sont conservées pendant 120 jours.
        </p>
        <button type="submit">ENVOYER</button>
      </form>

      {/* Pop up confirmation */}
      {isSubmitted && (
        <div className="popup">
          <div className="popup-header">
            <span className="popup-close-form" onClick={closePopup}>&times;</span>
          </div>
          <div className="popup-content">
            <Link to="/">
              <img className="PopUpLogoPicture" src="/Logo blanc horizontal.png" alt="logo-JPS" />
            </Link>
            {/* <p>Votre demande a bien été reçue. <br />Si vous avez besoin de nous faire parvenir des fichiers, merci de nous les envoyer par Whatsapp ou via <br /><a href="mailto:maeva@jpsfilms.com">maeva@jpsfilms.com</a></p> */}
            <p>Votre demande a bien été envoyée. <br />Notre équipe reviendra vers vous dans les plus brefs délais.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
