import React from 'react';

// Grid for main simulator

const RectangleGrid = ({ items, onClick }) => {
    return (
        <div className="rectangle-grid">
            {items.map((item, index) => (
                <div 
                    key={index} 
                    className="rectangle"
                    onClick={() => onClick(item)}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};

export default RectangleGrid;
