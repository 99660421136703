import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/commun/Header';
import Erreur from '../components/commun/Erreur';
import Footer from '../components/commun/Footer';
import BoutonsDevisRdvWhatsapp from '../components/commun/BoutonsDevisRdvWhatsapp';
import ArticleBlogDescriptif from '../components/blog/ArticleBlogDescriptif';
import { Helmet } from 'react-helmet';

const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const PolitiqueDeConfi = ({ customStyles }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data.length > 0 ? response.data.data[0].attributes : null;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des données.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const mentionsLegalesData = await fetchData(`${API_BASE_URL}/politique-de-confs?populate=*`);
        if (mentionsLegalesData) {
          setData(mentionsLegalesData);
        } else {
          setData(null);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (loading) return <div></div>;
  if (error) return <div><Erreur /></div>;

  return (
    <div className="mentions-legales-page">
      <Helmet>
        <title>Politique de confidentialité</title>
        <meta name="description" content={'Découvrez notre politique de confidentialité'} />
        <meta property="og:title" content={'Politique de confidentialité'} />
        <meta property="og:description" content={'Découvrez notre politique de confidentialité'} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="mentions-legales-container">
        {data ? <ArticleBlogDescriptif data={data} /> : <Erreur />}
      </div>
      <Footer />
      <BoutonsDevisRdvWhatsapp customStyles={customStyles?.floatingButtons} />
    </div>
  );
};

export default PolitiqueDeConfi;
