import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Erreur from '../commun/Erreur';

//Description subsection aside left of /template-sous-section

const SousSectionDescriptifs = ({ data }) => {
  const [h1Ref, h1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [blocUnRef, blocUnInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [blocDeuxRef, blocDeuxInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [blocTroisRef, blocTroisInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const { Titre, BlocTexteUn, BlocTexteDeux, BlocTexteTrois } = data;

  // render strapi optionnal text
  const renderContent = (blocks) => {
    if (!Array.isArray(blocks)) {
      console.error('Les blocs ne sont pas un tableau:', blocks);
      return <p className="paragraph">Aucun contenu à afficher.</p>;
    }

    return blocks.map((block, index) => {
      if (!block || !block.children) {
        return <p key={index} className="paragraph">Bloc sans contenu.</p>;
      }

      switch (block.type) {
        case 'heading':
          switch (block.level) {
            case 3:
              return (
                <h3 key={index} className="title-h3">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h3>
              );
            case 5:
              return (
                <h5 key={index} className="title-h5">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h5>
              );
            default:
              return (
                <h2 key={index} className="title-default">
                  {block.children[0]?.text || 'Titre sans contenu'}
                </h2>
              );
          }
        case 'paragraph':
          return (
            <p key={index} className="paragraph">
              {block.children.map((child, childIndex) => (
                <span key={childIndex}>{child.text}</span>
              ))}
            </p>
          );
        case 'list':
          return (
            <ul key={index} className="list">
              {block.children.map((listItem, itemIndex) => (
                <li key={itemIndex} className="list-item">
                  <i className="fa fa-check icon" aria-hidden="true"></i>
                  {listItem.children.map((child, childIndex) => (
                    <span key={childIndex}>{child.text}</span>
                  ))}
                </li>
              ))}
            </ul>
          );
        default:
          console.warn('Type de bloc inconnu:', block.type);
          return <p className="paragraph">Bloc inconnu.</p>;
      }
    });
  };

  // fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  if (!data) { return <div><Erreur /></div>; }
  
  return (
    <div className="sous-section-descriptifs">
      <motion.h1
        ref={h1Ref}
        variants={fadeInVariants}
        initial="hidden"
        animate={h1InView ? 'visible' : 'hidden'}
      >
        {Titre}
      </motion.h1>

      {BlocTexteUn && BlocTexteUn.length > 0 && (
        <motion.div
          ref={blocUnRef}
          className="bloc-texte bloc-texte-un"
          variants={fadeInVariants}
          initial="hidden"
          animate={blocUnInView ? 'visible' : 'hidden'}
        >
          {renderContent(BlocTexteUn)}
        </motion.div>
      )}

      {data.MediaUn && data.MediaUn.data.map(media => (
        <motion.img
          key={media.id}
          src={media.attributes.url}
          alt={media.attributes.name}
          variants={fadeInVariants}
          initial="hidden"
          animate={blocUnInView ? 'visible' : 'hidden'}
        />
      ))}

      {BlocTexteDeux && BlocTexteDeux.length > 0 && (
        <motion.div
          ref={blocDeuxRef}
          className="bloc-texte bloc-texte-deux"
          variants={fadeInVariants}
          initial="hidden"
          animate={blocDeuxInView ? 'visible' : 'hidden'}
        >
          {renderContent(BlocTexteDeux)}
        </motion.div>
      )}

      {data.MediaDeux && data.MediaDeux.data.map(media => (
        <motion.img
          key={media.id}
          src={media.attributes.url}
          alt={media.attributes.name}
          variants={fadeInVariants}
          initial="hidden"
          animate={blocDeuxInView ? 'visible' : 'hidden'}
        />
      ))}

      {BlocTexteTrois && BlocTexteTrois.length > 0 && (
        <motion.div
          ref={blocTroisRef}
          className="bloc-texte bloc-texte-trois"
          variants={fadeInVariants}
          initial="hidden"
          animate={blocTroisInView ? 'visible' : 'hidden'}
        >
          {renderContent(BlocTexteTrois)}
        </motion.div>
      )}
    </div>
  );
};

SousSectionDescriptifs.propTypes = {
  data: PropTypes.shape({
    Titre: PropTypes.string.isRequired,
    BlocTexteUn: PropTypes.array,
    BlocTexteDeux: PropTypes.array,
    BlocTexteTrois: PropTypes.array,
    MediaUn: PropTypes.object,
    MediaDeux: PropTypes.object,
  }),
};

SousSectionDescriptifs.defaultProps = {
  data: {
    BlocTexteUn: [],
    BlocTexteDeux: [],
    BlocTexteTrois: [],
    MediaUn: { data: [] },
    MediaDeux: { data: [] },
  },
};

export default SousSectionDescriptifs;
