import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/commun/Header';
import Footer from '../components/commun/Footer';
import JPSFilms from '../components/commun/JPSFilms';
import QuestionProjet from '../components/commun/QuestionProjet';
import Domaines from '../components/commun/Domaines';
import LogosPartenaires from '../components/accueil/LogosPartenaires';
import BandeauDescriptifUn from '../components/commun/BandeauDescriptifUn';
import BandeauMediaAnimation from '../components/accueil/BandeauMediaAnimation';
import BandeauMedia from '../components/commun/BandeauMedia';
import CarrouselBlog from '../components/commun/CarrouselBlog';
import Erreur from '../components/commun/Erreur';
import { Helmet } from 'react-helmet';

const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const Accueil = () => {
  const [bandeauDescriptifData, setBandeauDescriptifData] = useState(null);
  const [bandeauMediaAnimationData, setBandeauMediaAnimationData] = useState([]);
  const [bandeauMediaData, setBandeauMediaData] = useState(null);
  const [domainesData, setDomainesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des données.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const bandeauDescriptifResponse = await fetchData(`${API_BASE_URL}/accueil-bandeau-uns?populate=*`);
        if (bandeauDescriptifResponse) {
          const bandeauDescriptifItem = bandeauDescriptifResponse.find(item => item.id === 1);
          setBandeauDescriptifData(bandeauDescriptifItem?.attributes || null);
        }

        const bandeauMediaAnimationResponse = await fetchData(`${API_BASE_URL}/accueil-bandeau-deuxes?populate=*`);
        if (bandeauMediaAnimationResponse) {
          const sortedData = bandeauMediaAnimationResponse.sort((a, b) => a.id - b.id);
          setBandeauMediaAnimationData(sortedData);
        }

        const bandeauMediaResponse = await fetchData(`${API_BASE_URL}/accueil-medias?populate=*`);
        if (bandeauMediaResponse) {
          const bandeauMediaItem = bandeauMediaResponse.find(item => item.id === 1);
          setBandeauMediaData(bandeauMediaItem?.attributes || null);
        }
        
        const domainesResponse = await fetchData(`${API_BASE_URL}/accueil-domaines?populate=*`);
        setDomainesData(domainesResponse || []);
        
      } catch (error) {
        setError('Une erreur est survenue lors du chargement des données.');
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchAllData();
  }, []);
  
  const bandeauId1 = bandeauMediaAnimationData.find(item => item.id === 1);
  const bandeauId2 = bandeauMediaAnimationData.find(item => item.id === 2);
  const bandeauId3 = bandeauMediaAnimationData.find(item => item.id === 3);

  if (loading) return <div></div>; 
  if (error) return <div><Erreur /></div>;

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>Spécialiste du film adhésif JPS Films France</title>
        <meta name="description" content={bandeauDescriptifData.Introduction || 'Améliorer les performances  et la durabilité des vitrages.'} />
        <meta property="og:title" content={bandeauDescriptifData.Titre || 'Spécialiste du film adhésif JPS Films France'} />
        <meta property="og:description" content={bandeauDescriptifData.Introduction || 'Améliorer les performances  et la durabilité des vitrages.'} />
        <meta property="og:image" content={bandeauDescriptifData?.Media?.data?.attributes?.url || `${window.location.origin}/logo.png`} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
        {bandeauDescriptifData &&<BandeauDescriptifUn data={bandeauDescriptifData} primaryButtonLink="/nos-realisations" secondaryButtonLink="/contact"/>}
        {bandeauId1 && <BandeauMediaAnimation key={bandeauId1.id} data={bandeauId1} />}
        {bandeauId2 && <BandeauMediaAnimation key={bandeauId2.id} data={bandeauId2} />}
        {domainesData && <Domaines domainesData={domainesData} />}
        {bandeauId3 && <BandeauMediaAnimation key={bandeauId3.id} data={bandeauId3} />}
        <LogosPartenaires />
        {bandeauMediaData && <BandeauMedia data={bandeauMediaData} />}
        <JPSFilms />
        <CarrouselBlog />
        <QuestionProjet />
        <Footer />
    </>
  );
}

export default Accueil;