import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Erreur from './Erreur';

//JpsFilms Banner between BandeauMedia and NosArticles

const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const JPSFilms = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { ref: contentRef, inView } = useInView({triggerOnce: true,threshold: 0.3});

  // render strapi optionnal text
  const renderRichText = (blocks) => {
    if (!Array.isArray(blocks)) {
      return <p>Content is not available.</p>;
    }

    return blocks.map((block, index) => {
      switch (block.type) {
        case 'paragraph':
          return (
            <p
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              {block.children.map((child, i) => (
                <span key={i}>{child.text}</span>
              ))}
            </p>
          );
        default:
          return null;
      }
    });
  };

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des logos.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const response = await fetchData(`${API_BASE_URL}/bandeau-jps-films?populate=*`);
        if (response && response.length > 0) {
          const attributes = response[0].attributes;
          setData({
            ...attributes,
            Descriptif: attributes.DescriptifBlock
          });
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, []);

  //fade in aimation
  const slideFromBottom = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  if (loading) return <div></div>;
  if (error) return <div><Erreur /></div>;

  return (
    <div className="jps-films-banner">
      <motion.div
        className="content"
        ref={contentRef}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={slideFromBottom}
        transition={{ duration: 0.8 }}
      >
        <h1>{data.Titre}</h1>
        <div className="descriptif">
          {renderRichText(data.Descriptif)}
        </div>
        <Link to="/qui-sommes-nous">
          <button>{data.Boutton}</button>
        </Link>
      </motion.div>
      <div className="image-container">
        {data.Media && data.Media.data && (
          <img src={data.Media.data[0].attributes.url} alt={data.Media.data[0].attributes.alternativeText || 'JPS Films'} />
        )}
      </div>
    </div>
  );
};

export default JPSFilms;
