import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

//SubMenu articlecard present in /template-section and /template-sous-section

const ArticleCard = ({ articles }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3
  });
  
  //fade in animation
  const fadeInVariants = (index) => ({
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8, delay: index * 0.2 } }
  });
  
  return (
    <motion.div
      ref={ref}
      className="article-list"
    >
      {articles.length > 0 ? (
        articles.sort((a, b) => a.id - b.id).map((article, index) => (
          <motion.div
            key={article.id}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={fadeInVariants(index)}
            className="article-card"
          >
            <Link to={`/template-sous-section/${article.id}`} className="article-link">
              <img
                src={article.attributes.image?.data?.attributes?.url || '/path-to-default-image/default-image.jpg'}
                alt={article.attributes.title || 'Image de l\'article'}
                className="article-image"
              />
              <div className="article-content">
                <h3>{article.attributes.title}</h3>
                <p className='p-content'>{article.attributes.content}</p>
                <p className='p-intro'>{article.attributes.Introduction}</p>
              </div>
            </Link>
          </motion.div>
        ))
      ) : (
        <div>Aucun article disponible</div>
      )}
    </motion.div>
  );
};

export default ArticleCard;
