import React from 'react';
import { useNavigate } from 'react-router-dom';
import AsideSimulateur from '../../components/simulateur/AsideSimulateur';

const SimulateurSolaireQuatre = () => {
    const navigate = useNavigate();

    const handleRectangleClick = () => {
        navigate('/simulateur/solaire-cinq');
    };

    return (
        <div className="simulateur">
            <AsideSimulateur page={4} />
            <div className="simulateur-content">
                <main className="main">
                    <h1>Quelle est l’exposition au soleil ?</h1>
                    <div className="rectangle-grid smaller-rectangles">
                        <div className="rectangle" onClick={handleRectangleClick}>Forte</div>
                        <div className="rectangle" onClick={handleRectangleClick}>Moyenne</div>
                        <div className="rectangle" onClick={handleRectangleClick}>Faible</div>
                        <div className="rectangle" onClick={handleRectangleClick}>N/A</div>
                    </div>
                    <div className="image-container">
                        <img src="/assets/images/Simulateur/Solaire4.png" alt="Exposition au soleil" />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default SimulateurSolaireQuatre;

