import React from 'react';
import { useNavigate } from 'react-router-dom';
import RectangleGrid from '../../components/simulateur/RectangleGrid';
import AsideSimulateur from '../../components/simulateur/AsideSimulateur';

const SimulateurUn = () => {
    const navigate = useNavigate();

    const items = [
        'Entreprise', 'Collectivité', 'Indépendant', 'Particulier', 'Revendeur'
    ];

    const handleRectangleClick = (item) => {
        navigate('/simulateur/deux');
    };

    return (
        <div className="simulateur">
            <AsideSimulateur page={1} showBackButton={false} />
            <div className="simulateur-content">
                <main className="main">
                    <h1>Vous êtes...</h1>
                    <RectangleGrid items={items} onClick={handleRectangleClick} />
                </main>
            </div>
        </div>
    );
};

export default SimulateurUn;
