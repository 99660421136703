import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import BoutonsDevisRdvWhatsapp from './BoutonsDevisRdvWhatsapp';

const Erreur = () => {
  return (
    <>
      <Header />
      <BoutonsDevisRdvWhatsapp />
      <div className="error-container">
        <div className="img-error">
            <img className='cassePicture' src="/assets/images/casse.png" alt="casse-JPS" />
        </div>
        <div className="error-content">
            <h2>Oups la page que vous cherchez semble cassée</h2>
            <p>On espère que ce n'est que cette page et pas votre vitrage. Sinon, cela <br />tombe bien, nous sommes spécialistes en la matière.</p>
            <a className="button-link" href="/">RETOUR À LA PAGE D'ACCUEIL</a>
        </div>
      </div>
      <Footer />
    </>
  );
}

Erreur.propTypes = {
  message: PropTypes.string,
};

Erreur.defaultProps = {
  message: '',
};

export default Erreur;