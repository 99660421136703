import React from 'react';
import { useNavigate } from 'react-router-dom';
import AsideSimulateur from '../../components/simulateur/AsideSimulateur';

const SimulateurSolaireCinq = () => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate('/simulateur/final', { state: { references: 5, source: 'solaire' } });
    };

    return (
        <div className="simulateur">
            <AsideSimulateur page={5} />
            <div className="simulateur-content">
                <main className="main">
                    <h1>Quel style aimeriez-vous ?</h1>
                    <div className="card-grid">
                        <div className="card" onClick={handleCardClick}>
                            <img src="/assets/images/Simulateur/Solaire51.png" alt="Neutre" />
                            <p>Neutre</p>
                        </div>
                        <div className="card" onClick={handleCardClick}>
                            <img src="/assets/images/Simulateur/Solaire52.png" alt="Teinté" />
                            <p>Teinté</p>
                        </div>
                        <div className="card" onClick={handleCardClick}>
                            <img src="/assets/images/Simulateur/Solaire53.png" alt="Miroir" />
                            <p>Miroir</p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default SimulateurSolaireCinq;

