import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion'; 
import BandeauMedia from '../components/commun/BandeauMedia';
import Header from '../components/commun/Header';
import Footer from '../components/commun/Footer';
import JPSFilms from '../components/commun/JPSFilms';
import QuestionProjet from '../components/commun/QuestionProjet';
import CarrouselBlog from '../components/commun/CarrouselBlog';
import BoutonsDevisRdvWhatsapp from '../components/commun/BoutonsDevisRdvWhatsapp';
import Erreur from '../components/commun/Erreur';
import { Helmet } from 'react-helmet';

const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const NosRealisations = ({ customStyles }) => {
  const [mediaData, setMediaData] = useState(null);
  const [sections, setSections] = useState([]);
  const [cartes, setCartes] = useState([]);
  const [filteredCartes, setFilteredCartes] = useState([]);
  const [selectedSection, setSelectedSection] = useState('Toutes les catégories');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [articles, setArticles] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  // Fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des données.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  //Fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const mediaData = await fetchData(`${API_BASE_URL}/nos-realisations-media-bandeaus?populate=*`);
        setMediaData(mediaData?.[0] || null);

        const sectionsData = await fetchData(`${API_BASE_URL}/nos-realisations-2-sections?populate=*`);
        setSections(sectionsData || []);

        const cartesData = await fetchData(`${API_BASE_URL}/nos-realisations-3-cartes?populate=*`);
        setCartes(cartesData || []);
        setFilteredCartes(cartesData || []);

        const articlesData = await fetchData(`${API_BASE_URL}/articles?populate=*`);
        setArticles(articlesData || []);
        
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  // Filter fonction
  useEffect(() => {
    if (selectedSection === 'Toutes les catégories') {
      setFilteredCartes(cartes);
    } else {
      const sectionId = sections.find(section => section.attributes?.NomSection === selectedSection)?.id;
      if (sectionId) {
        setFilteredCartes(cartes.filter(carte => carte.attributes?.sectionId === sectionId));
      } else {
        setFilteredCartes([]);
      }
    }
  }, [selectedSection, cartes, sections]);

  //Mobile case 
  useEffect(() => {
    const handleResize = () => {
      const mobileView = isMobile();
      setIsMobileView(mobileView);
      if (mobileView) {
        setIsPopupOpen(false);
        document.body.style.overflow = 'auto';
      } else {
        setIsFilterMenuOpen(false); 
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const isMobile = () => window.innerWidth <= 768;

  const openPopup = (carte) => {
    if (!isMobile()) { 
      setSelectedCard(carte);
      setIsPopupOpen(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const closePopup = () => {
    setSelectedCard(null);
    setIsPopupOpen(false);
    document.body.style.overflow = 'auto';
  };

  //Fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5 } }
  };

  
  
  if (loading) return <div></div>;
  if (error) return <div><Erreur /></div>;
  if (!mediaData) return <div><Erreur /></div>;
  
  const mediaAttributes = mediaData.attributes?.Media?.data?.[0]?.attributes || {};
  const bandeauData = {
    Titre: mediaData.attributes?.Titre || '',
    Introduction: mediaData.attributes?.Introduction || '',
    Media: {
      data: {
        attributes: {
          url: mediaAttributes?.url || '',
          ext: mediaAttributes?.ext || '',
          alternativeText: mediaAttributes?.alternativeText || '',
        }
      }
    }
  };  
  
  return (
    <>
      <Helmet>
        <title>Nos réalisations</title>
        <meta name="description" content={mediaData.attributes.Introduction || 'Nous intervenons depuis plus de 40 ans partout en France et en Suisse. Découvrez certains de nos projets.'} />
        <meta property="og:title" content={mediaData.attributes.Titre || 'Nos réalisations'} />
        <meta property="og:description" content={mediaData.attributes.Introduction || 'Nous intervenons depuis plus de 40 ans partout en France et en Suisse. Découvrez certains de nos projets.'} />
        <meta property="og:image" content={mediaData.attributes?.Media?.data[0]?.attributes?.url || `${window.location.origin}/logo.png`} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />
      <div className="nos-realisations">
        
        {/* Banner Header */}
        <div className="nos-realisations__bandeau">
          <BandeauMedia data={bandeauData} hideTitle={true} />
          <motion.div
            className="nos-realisations__overlay"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <h2 className="nos-realisations__title">{bandeauData.Titre}</h2>
            <p className="nos-realisations__introduction">{bandeauData.Introduction}</p>
          </motion.div>
          <BoutonsDevisRdvWhatsapp customStyles={customStyles?.floatingButtons} />
        </div>

        {/* Filter section */}
        <div className="filter-section">
          {isMobileView ? (
            <>
              <div className="filter-header" onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}>
                <h4>{selectedSection}</h4>
                <span className="chevron" onClick={(e) => { e.stopPropagation(); setIsFilterMenuOpen(!isFilterMenuOpen); }}>
                  <i className={`fas fa-chevron-${isFilterMenuOpen ? 'up' : 'down'}`}></i>
                </span>
              </div>
              {isFilterMenuOpen && (
                <div className="filter-dropdown">
                  {sections.map(section => (
                    <button
                      key={section.id}
                      onClick={() => {
                        setSelectedSection(section.attributes?.NomSection || 'Toutes les catégories');
                        setIsFilterMenuOpen(false);
                      }}
                      className={selectedSection === section.attributes?.NomSection ? 'active' : ''}
                    >
                      {section.attributes?.NomSection}
                    </button>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="filter-buttons">
              {sections.map(section => (
                <button
                  key={section.id}
                  onClick={() => setSelectedSection(section.attributes?.NomSection || 'Toutes les catégories')}
                  className={selectedSection === section.attributes?.NomSection ? 'active' : ''}
                >
                  {section.attributes?.NomSection}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Main section */}
        <div className="card-container">
          {filteredCartes.length > 0 ? (
            filteredCartes.map(carte => (
              <div
                key={carte.id}
                className="card"
                onClick={() => openPopup(carte)}
              >
                <img src={carte.attributes?.Media?.data?.[0]?.attributes?.url || ''} alt={carte.attributes?.Titre || 'Image'} />
                <div className="card-text-section">
                  <h3>{carte.attributes?.Titre || 'Titre'}</h3>
                  <p className="card-location">{carte.attributes?.Lieu || 'Lieu'}</p>
                  <p className="card-description">{carte.attributes?.Descriptif || 'Descriptif'}</p>
                  <p className="card-details">{carte.attributes?.Details || 'Détails'}</p>
                </div>
              </div>
            ))
          ) : (
            <p>Aucune carte à afficher</p>
          )}
        </div>
          
        {/* Pop up card */}
        {isPopupOpen && (
          <div className="popup-real" onClick={closePopup}>
            <div className="popup-content-real" onClick={(e) => e.stopPropagation()}>
              <span className="popup-close-real" onClick={closePopup}>&times;</span>
              
              <img src={selectedCard.attributes?.Media?.data?.[0]?.attributes?.url || ''} alt={selectedCard.attributes?.Titre || 'Image'} />
              <div className="selected-card-text-section">
                <h3>{selectedCard.attributes?.Titre || 'Titre'}</h3>
                <p className="selected-card-location">{selectedCard.attributes?.Lieu || 'Lieu'}</p>
                <p className="selected-card-description">{selectedCard.attributes?.Descriptif || 'Descriptif'}</p>
                <p className="selected-card-details">{selectedCard.attributes?.Details || 'Détails'}</p>
              </div>
            </div>
          </div>
        )}
        <JPSFilms />
        <CarrouselBlog articles={articles} />
        <QuestionProjet />
        <Footer />
      </div>
    </>
  );
};

export default NosRealisations;
