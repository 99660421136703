import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Erreur from './Erreur';

//Nav for Desktop only

const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const NavBar = () => {
  const [sections, setSections] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [submenuData, setSubMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubmenu, setLoadingSubmenu] = useState(false);
  const [error, setError] = useState(null);

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des données.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  //fetch data : sections
  useEffect(() => {
    const fetchAllData = async () => {
      const sectionsData = await fetchData(`${API_BASE_URL}/sections?populate=*`);
      if (sectionsData) {
        setSections(sectionsData);
      }
      setLoading(false);
    };

    fetchAllData();
  }, []);

  //fetch data : articles (submenu) by sections
  useEffect(() => {
    const fetchSubmenuData = async () => {
      if (activeSection !== null) {
        setLoadingSubmenu(true);
        const submenuData = await fetchData(`${API_BASE_URL}/articles?filters[sectionId][$eq]=${activeSection}&populate=*`);
        if (submenuData) {
          setSubMenuData(submenuData);
        }
        setLoadingSubmenu(false);
      }
    };

    fetchSubmenuData();
  }, [activeSection]);

  //SubMenu Banner 
  const handleMouseEnter = (id) => {
    setActiveSection(id);
  };

  const handleMouseLeave = () => {
    setActiveSection(null);
  };

  //fade in animation
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  if (loading) return <div></div>;
  if (error) return <div><Erreur /></div>;

  return (
    <div>
      <nav className='navbar'>

        {/* Sections (Blue row) */}
        <motion.ul
          className='sections'
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          {sections.sort((a, b) => a.id - b.id).map((section) => (
            <motion.li
              key={section.id}
              className={`section ${activeSection === section.id ? 'active' : ''}`}
              onMouseEnter={() => handleMouseEnter(section.id)}
              onMouseLeave={handleMouseLeave}
              variants={fadeInVariants}
            >
              <img
                src={section.attributes.Icone?.data?.attributes?.url || 'fa-circle'}
                alt={section.attributes.Icone?.data?.attributes?.alternativeText || 'Icône de la section'}
                className="section-icon"
              />
              <Link to={`/section/${section.id}`}>
                <span>{section.attributes.title}</span>
              </Link>
            </motion.li>
          ))}
        </motion.ul>

        {/* SubMenu (white banner) after selecting a section */}
        {activeSection !== null && (
          <motion.div
            className='bandeau'
            onMouseEnter={() => handleMouseEnter(activeSection)}
            onMouseLeave={handleMouseLeave}
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            {loadingSubmenu ? (
              <div></div> 
            ) : (
              submenuData.sort((a, b) => a.id - b.id).map((item) => (
                <motion.div
                  key={item.id}
                  className='bandeau-item'
                  variants={fadeInVariants}
                >
                  <Link to={`/template-sous-section/${item.id}`}>
                    {item.attributes.image?.data?.attributes?.url ? (
                      <img
                        src={item.attributes.image.data.attributes.url}
                        alt={item.attributes.title}
                        className='bandeau-item-image'
                      />
                    ) : (
                      <div className='image-placeholder'>Image non disponible</div>
                    )}
                    <h4>{item.attributes.title}</h4>
                    <p>{item.attributes.content}</p>
                  </Link>
                </motion.div>
              ))
            )}
          </motion.div>
        )}

        <Link to="/guides-reglementations" className='guides-regulations'>
          Guides & Réglementations
        </Link>
      </nav>
    </div>
  );
}

export default NavBar;