import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const QuestionProjet = () => {
  const imageUrl = `${process.env.PUBLIC_URL}/assets/images/Questions-projet.png`;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1, delay: 1 } }
  };

  return (
    <motion.div
      className="question-projet"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="question-projet__image-container">
        <div
          className="question-projet__image"
          style={{
                backgroundImage: `url(${imageUrl})`
          }}
        ></div>
      </div>
      <div className="question-projet__text-container">
        <h2 className="question-projet__title">Une question ? Un projet ? Nous sommes à votre écoute</h2>
        <p className="question-projet__description">
          Contactez nos spécialistes du film adhésif pour vitrage pour vous accompagner dans votre projet.
          Notre équipe est à votre disposition du lundi au vendredi de 8h à 18h.
        </p>
        <Link to="/contact">
          <button className="question-projet__button">échanger avec nous</button>      
        </Link>
      </div>
    </motion.div>
  );
};


export default QuestionProjet;


